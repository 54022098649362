:root {
	--clr-neon-fore: hsl(192, 90%, 41%);
}

body {
	display: grid;
	place-items: center;
	background-color: black;
}

.neon-sign-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.neon-sign {
	color: var(--clr-neon-fore);
	font-family: "Courier New", Courier, sans-serif, monospace;
	margin: 0;
	border: 0.5rem solid;
	border-radius: 1.5rem;
	padding: 1rem 1rem;
	text-align: center;
	text-shadow: 0 0 7px var(--clr-neon-fore), 0 0 17px var(--clr-neon-fore),
		0 0 30px var(--clr-neon-fore), 0 0 55px var(--clr-neon-fore),
		0 0 78px var(--clr-neon-fore);
	box-shadow: 0 0 7px var(--clr-neon-fore), 0 0 14px var(--clr-neon-fore),
		0 0 21px var(--clr-neon-fore), 0 0 28px var(--clr-neon-fore),
		0 0 35px var(--clr-neon-fore), inset 0 0 7px var(--clr-neon-fore),
		inset 0 0 14px var(--clr-neon-fore), inset 0 0 21px var(--clr-neon-fore);
	position: relative;
}

.neon-sign::before {
	background: var(--clr-neon-fore);
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	transform: perspective(5rem) rotateX(100deg) translateZ(-5rem) scale(1, 0.2);
	filter: blur(4rem);
}

@media screen and (min-width: 768px) {
	.neon-sign-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}
	.neon-sign {
		color: var(--clr-neon-fore);
		font-family: "Courier New", Courier, monospace;
		font-size: 5rem;
		margin: 0;
		border: 0.5rem solid;
		max-width: 624px;
		border-radius: 1.5rem;
		padding: 1rem 1rem;
		text-align: center;
		text-shadow: 0 0 7px var(--clr-neon-fore), 0 0 17px var(--clr-neon-fore),
			0 0 30px var(--clr-neon-fore), 0 0 55px var(--clr-neon-fore),
			0 0 78px var(--clr-neon-fore);
		box-shadow: 0 0 7px var(--clr-neon-fore), 0 0 14px var(--clr-neon-fore),
			0 0 21px var(--clr-neon-fore), 0 0 28px var(--clr-neon-fore),
			0 0 35px var(--clr-neon-fore), inset 0 0 7px var(--clr-neon-fore),
			inset 0 0 14px var(--clr-neon-fore), inset 0 0 21px var(--clr-neon-fore);
		position: relative;
	}
	.neon-sign::before {
		background: var(--clr-neon-fore);
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		transform: perspective(10rem) rotateX(100deg) translateZ(-10rem)
			scale(1, 0.2);
		filter: blur(8rem);
	}
}
